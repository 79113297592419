/* SITE THEME FOR PRIDE TRUCK SALES */
$theme-primary: (
   50 : #ffe3e0,
    100 : #ffb9b3,
    200 : #ff8b80,
    300 : #ff5d4d,
    400 : #ff3a26,
    500 : #ff1700,
    600 : #ff1400,
    700 : #ff1100,
    800 : #ff0d00,
    900 : #ff0700,
    A100 : #ffffff,
    A200 : #fff2f2,
    A400 : #ffc0bf,
    A700 : #ffa6a6,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

// $black-87-opacity

$theme-accent: (
    50 : #feeee0,
    100 : #fcd4b3,
    200 : #fab880,
    300 : #f79c4d,
    400 : #f68626,
    500 : #f47100,
    600 : #f36900,
    700 : #f15e00,
    800 : #ef5400,
    900 : #ec4200,
    A100 : #ffffff,
    A200 : #ffe6e0,
    A400 : #ffbead,
    A700 : #ffaa93,
    contrast: (
        50: rgba(black, 0.87),
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

